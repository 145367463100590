<script setup lang="ts"></script>

<template>
  <div>
    <div class="flex h-32 max-w-4xl items-end rounded-lg bg-gray-800 px-4">
      <img src="/img/logo-full.svg" alt="GRCschema.org" width="200" height="200" class="py-8" />
    </div>

    <div class="py-4"></div>

    <ContentDoc
      class="prose max-w-4xl overflow-auto prose-h1:text-gray-700 prose-a:text-cyan-700 prose-a:no-underline hover:prose-a:underline prose-code:overflow-x-auto prose-pre:bg-[#24292e]"
    />
  </div>
</template>
